import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IOfficeList } from '../interfaces/offices/office-list';
import { IOfficeDetails } from '../interfaces/offices/office-details';
import { IIdResult } from '../interfaces/id-result';
import { AddOfficeModel } from '../models/offices/add-office-model';
import { ISelectResult } from '../interfaces/select-result';
import { IOfficeEdit } from '../interfaces/offices/office-edit';
import { OfficeSourcesModel } from '../models/offices/office-sources-model';
import { IOfficeSources } from '../interfaces/offices/office-sources';
import { IPage } from '../interfaces/page';
import { QueryPageModel } from '../models/query-page-model';
import { OfficeModel } from '../models/offices/office-model';
import { IOffice } from '../interfaces/offices/office';
import { IOfficeTheme } from '../interfaces/offices/office-theme';

@Injectable({
  providedIn: 'root'
})
export class OfficeService {

  private baseUrl = `${environment.apiUrl}/offices`;

  private apiPath = environment.apiUrl + 'offices/';

  constructor(
    private http: HttpClient
  ) { }

  getAll(): Observable<IOfficeList[]> {
    return this.http.get<IOfficeList[]>(this.apiPath)
      .pipe(map(result => {
        return result;
      }));
  }

  getAgents(officeId: string): Observable<ISelectResult[]> {
  return this.http.get<ISelectResult[]>(`${environment.apiUrl}offices/${officeId}/agents`);
  }

  get(id: string): Observable<IOffice> {
    return this.http.get<IOffice>(this.apiPath + id)
      .pipe(map(result => {
        return result;
      }));
  }

  getStyles(officeId: string): Observable<IOfficeTheme[]> {
    return this.http.get<IOfficeTheme[]>(this.apiPath + `get-styles/${officeId}`)
      .pipe(map(result => {
        return result;
      }));
  }

  getSources(id: string): Observable<IOfficeSources> {
    return this.http.get<IOfficeSources>(this.apiPath + id + '/sources')
      .pipe(map(result => {
        return result;
      }));
  }

  update(id: string, model: OfficeModel): Observable<any> {
    var headers = new HttpHeaders({
      'Content-Type': 'application/json',
    })
    return this.http.put<any>(this.apiPath + id, JSON.stringify(model), { headers: headers })
      .pipe(map(result => {
        return result;
      }));
  }

  updateSources(id: string, model: OfficeSourcesModel): Observable<any> {
    var headers = new HttpHeaders({
      'Content-Type': 'application/json',
    })
    return this.http.put<any>(this.apiPath + id + '/sources', JSON.stringify(model), { headers: headers })
      .pipe(map(result => {
        return result;
      }));
  }

  delete(id: string): Observable<any> {
    return this.http.delete<any>(this.apiPath + id)
      .pipe(map(result => {
        return result;
      }));
  }

  count(): Observable<number> {
    return this.http.get<number>(this.apiPath + 'count')
      .pipe(map(result => {
        return result;
      }));
  }

  add(model: AddOfficeModel): Observable<IIdResult> {
    var headers = new HttpHeaders({
      'Content-Type': 'application/json',
    })
    return this.http.post<IIdResult>(this.apiPath, JSON.stringify(model), { headers: headers })
      .pipe(map(result => {
        return result;
      }));
  }

  getPage(model: QueryPageModel): Observable<IPage<IOfficeList>> {
    var headers = new HttpHeaders({
      'Content-Type': 'application/json',
    })
    return this.http.post<IPage<IOfficeList>>(this.apiPath + 'page', JSON.stringify(model), { headers: headers })
      .pipe(map(result => {
        return result;
      }));
  }

  getForUserWithoutCurrentAssignee(): Observable<ISelectResult[]> {
    return this.http.get<ISelectResult[]>(this.apiPath + 'for-user')
      .pipe(map(result => {
        return result;
      }));
  }

  getForUserWithCurrentAssignee(assignToId: string): Observable<ISelectResult[]> {
    return this.http.get<ISelectResult[]>(this.apiPath + 'for-user/' + assignToId)
      .pipe(map(result => {
        return result;
      }));
  }

  isNameTaken(name: string): Observable<boolean> {
    var headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    var model = {
      key: 'name',
      value: name
    };
    return this.http.post<boolean>(this.apiPath + 'is-name-taken', JSON.stringify(model), { headers: headers })
      .pipe(map(result => {
        return result;
      }));
  }
}
