import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { TickerComponent } from './ticker/ticker.component';
import { ReportGuard } from '../auth/guards/report.guard';
import { TickerMonthGuard } from '../auth/guards/ticker-month.guard';
import { TickerMonthComponent } from './ticker-month/ticker-month.component';

const routes: Routes = [
  {
    path: 'ticker',
    component: TickerComponent,
    canActivate: [ReportGuard]
  },
  {
    path: 'ticker_month',
    component: TickerMonthComponent,
    canActivate: [TickerMonthGuard]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TickerRoutingModule { }
