import { Injectable } from "@angular/core";
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { AddSpendModel } from "../models/spends/add-spend-model";
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';
import { QueryPageModel } from "../models/query-page-model";
import { IPage } from "../interfaces/page";
import { ISpendList } from "../interfaces/spends/spend-list";
import { IUserEdit } from "../interfaces/users/user-edit";
import { ISpendEdit } from "../interfaces/spends/spend-edit";
import { EditSpendModel } from "../models/spends/edit-spend-model";
import { FilterSpendModel } from "../models/spends/filter-spend-model";


@Injectable({
    providedIn: 'root'
  })
  
export class SpendsService {
    private apiPath = environment.apiUrl + 'spends/';

    constructor(
      private http: HttpClient
    ) { }

    add(model: AddSpendModel): Observable<any> {
        var headers = new HttpHeaders({
          'Content-Type': 'application/json',
        })
        return this.http.post<any>(this.apiPath, JSON.stringify(model), { headers: headers })
          .pipe(map(result => {
            return result;
          }));
    }

    getPage(model: QueryPageModel): Observable<IPage<ISpendList>> {
      var headers = new HttpHeaders({
        'Content-Type': 'application/json',
      })
      return this.http.post<IPage<ISpendList>>(this.apiPath + 'page', JSON.stringify(model), { headers: headers })
        .pipe(map(result => {
          return result;
        }));
    }

    showPopup(id: string): Observable<boolean> {
      return this.http.get<boolean>(this.apiPath + 'popup/' + id)
        .pipe(map(result => {
          return result;
        }));
    }

    get(id: string): Observable<ISpendEdit> {
      return this.http.get<ISpendEdit>(this.apiPath + id)
          .pipe(map(result => {
              return result;
          }));
    }

    update(id: string, model: EditSpendModel): Observable<any> {
      var headers = new HttpHeaders({
        'Content-Type': 'application/json',
      })
      return this.http.put<any>(this.apiPath + id, JSON.stringify(model), { headers: headers })
        .pipe(map(result => {
          return result;
        }));
    }

    delete(id: string): Observable<any> {
      return this.http.delete<any>(this.apiPath + id)
          .pipe(map(result => {
              return result;
          }));
    }

    exportFile(model: FilterSpendModel): Observable<HttpResponse<Blob>> {
      var headers = new HttpHeaders({
        'Content-Type': 'application/json',
      });
      
      var result = this.http.post(this.apiPath + 'export-file', JSON.stringify(model),  {observe: 'response', headers: headers, responseType: 'blob' })
            .pipe(
              map(result => {
                return result; 
              }));
  
      return result;
    }
}