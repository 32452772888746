import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TickerRoutingModule } from './ticker-routing.module';
import { TickerComponent } from './ticker/ticker.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from '../material-module';
import { TickerItemComponent } from './components/ticker-item/ticker-item.component';
import { TickerMonthComponent } from './ticker-month/ticker-month.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    TickerComponent,
    TickerItemComponent,
    TickerMonthComponent,
  ],
  imports: [
    CommonModule,
    TickerRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    NgxChartsModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class TickerModule { }
