import { Injectable } from "@angular/core";
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpInterceptor,
    HttpRequest,
    HttpEvent,
    HttpHandler,
    HttpErrorResponse } from '@angular/common/http';
import { NotificationStore } from './notifications.store';
import { Notification, NotificationLevel } from "./notification";


@Injectable()
export class NotificationHttpInterceptor implements HttpInterceptor {
    
    constructor(
        private notificationStore: NotificationStore,
        private router: Router) { }
    
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(tap(
            event => {
            },

            err => {
              if (err instanceof HttpErrorResponse && (err.status == 500 || err.status == 0 || err.status == 401)) {   
                let errorMessage = err.message;
                
                if(err.error.Errors)
                {
                    err.error.Errors.forEach((e) => {
                        this.notificationStore.push(new Notification(e, NotificationLevel.Error));
                    });
                }
                else
                {
                    this.notificationStore.push(new Notification(errorMessage, NotificationLevel.Error));
                }
              }
            }
          ));
    }
}
