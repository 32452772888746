import { Component, ElementRef, EventEmitter, OnInit, Output } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { ISource } from 'src/app/interfaces/sources/source';
import { AddSaleModel } from 'src/app/models/sales/add-sale-model';
import { SourceService } from 'src/app/services/source.service';
import { SalesService } from 'src/app/services/sales.service';
import { StateService } from 'src/app/services/state.service';
import { IBadRequest } from 'src/app/interfaces/bad-request';
import { IState } from 'src/app/interfaces/states/state';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { SpendPopupComponent } from '../spend-popup/spend-popup.component';
import { SpendsService } from 'src/app/services/spends.serice';
import { interval, timer } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { TickerService } from 'src/app/services/ticker.service';
import { IOfficeTicker } from 'src/app/interfaces/offices/office-ticker';
import { IBabyTickerData } from 'src/app/interfaces/offices/baby-ticker';
import { ILastTimeData } from 'src/app/interfaces/offices/last-time-data';
import { OfficeService } from 'src/app/services/office.service';
import { OfficeThemeModel } from 'src/app/models/offices/office-theme-model';
import { IOfficeTheme } from 'src/app/interfaces/offices/office-theme';
import { StyleSharedService } from 'src/app/shared/style-shared-service';
import { ISelectResult } from 'src/app/interfaces/select-result';

@Component({
  selector: 'ast-office',
  templateUrl: './office.component.html',
  styleUrls: ['./office.component.scss']
})
export class OfficeComponent implements OnInit {

  private model: AddSaleModel = new AddSaleModel();

  public addForm: FormGroup;

  public userName: string = '';
  public officeName: string = '';

  public healthTotal: number = 0;
  public salesToday: number = 0;
  public salesAll: number;
  public isLoading: boolean = false;
  public isTickerLoading:boolean = false;


  public states: IState[] = [];
  public sources: ISource[] = [];
  public agents: ISelectResult[] = [];
  public badRequest: IBadRequest;
  private dialogRef:any;

  private dataEventSubscription = null;
  private updateEventSubscription = null;

  public tickerData: IBabyTickerData[];
  private topSaleDate:Date;
  //public lastTimeData:ILastTimeData = null;
  public lastTimeData:string = '0:00:00';

 

  public planTypes: string[] = [
    'HEALTH ONLY',
    'HEALTH + ANC',
    'ANC ONLY',
    'HEALTH + ANC + RX SAVERS',
    'HEALTH + RX SAVERS',
    'RX SAVERS ONLY'
  ];

  

  constructor(
    private router: Router,
    private dialog:MatDialog,
    private authService: AuthService,
    private sourceService: SourceService,
    private salesService: SalesService,
    private stateService: StateService,
    private spendsService:SpendsService,
    private tickerService: TickerService,
    private officeService: OfficeService,
    private elementRef:ElementRef,
    private styleSharedService:StyleSharedService
  ) { }

  ngOnInit(): void {
    this.userName = this.authService.getName();
    this.officeName = this.authService.getOffice()

    this.isTickerLoading = true;

    this.initForm();

    this.getPopupFlag();
    this.getAgents();
    this.getSalesCount();
    this.getStates();
    this.getSources();
    this.initPopupTimer();
    this.getStyles();

    this.dataEventSubscription = this.tickerService.dataRecievedEvent.subscribe(() => {
      //this.data = this.tickerService.data;
      this.setTickerData();
      //this.getLastSaleTime();
    });
    
    this.updateEventSubscription = this.tickerService.updateRecievedEvent.subscribe(() => {
      this.setTickerData()
    });

    this.tickerService.startConnection();
    this.tickerService.addTickerListener();

    timer(1000, 1000).subscribe(val => {
      this.getLastSaleTime();
    });

  }

  ngOnDestroy(): void {
    if (this.dataEventSubscription) {
      this.dataEventSubscription.unsubscribe();
    }
    if (this.updateEventSubscription) {
      this.updateEventSubscription.unsubscribe();
    }
    this.tickerService.stopConnection();
  }

  private getAgents(): void {
    const officeId = this.authService.getOfficeId();
  
    this.officeService.getAgents(officeId).subscribe(
      result => {
        this.agents = result;
      },
      error => {
        console.error('Error loading agents', error);
      }
    );
  }


  public showSales() {
    let url = 'office-sales';
    window.open(url, "_blank");
  }

  private setTickerData() {
    let sources = this.tickerService.data.sources.sort((a, b) => b.value - a.value).slice(0, 5);
    let offices = this.tickerService.data.tickerData.sort((a, b) => b.dailySalesCount - a.dailySalesCount).slice(0, 5);

    this.topSaleDate = this.tickerService.data.topSaleDate;
    
    this.tickerData = [];

    offices.forEach((element, index) => {
      let item:IBabyTickerData = {
        name: element.name,
        source : sources[index].name,
        sales: element.dailySalesCount
      };

      this.tickerData.push(item);
    });

    this.isTickerLoading = false;
  }
  

  private getLastSaleTime()
  {
    if(this.topSaleDate == undefined || this.tickerData == null)
      return;
    
    let today = new Date().getTime();
    let lastSaleDate = new Date(this.topSaleDate);

    let diff = today - lastSaleDate.getTime();

    let days = Math.floor(diff / (1000 * 60 * 60 * 24));
    let hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    let minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    //let seconds = Math.floor((diff % (1000 * 60)) / 1000);


    this.lastTimeData = days.toString() + ':' + ("0" + hours).slice(-2) + ':' + ("0" + minutes).slice(-2); 
  }

  private getPopupFlag():void {
    
    this.badRequest = null;
    var officeId = this.authService.getOfficeId();
    this.spendsService.showPopup(officeId).subscribe(
      result => {
       
        if(result)
          this.showPopup();

        if (this.badRequest) {
          this.badRequest = null;
        }
      },
      error => {
        this.badRequest = error.error;
       
      },
      () => {
       
      }
    );
  }

  private initPopupTimer():void {
    timer(1000, 60*60*1000).subscribe(() => {
      
      const currentDate = new Date();
      
      let day = currentDate.getDay();
      let hours = currentDate.getHours();


      if(day != 1 || hours > 6)
        return;
      
      this.getPopupFlag();
    })

  }

  private showPopup():void {
    if (this.dialogRef) 
      return;
    
    this.dialogRef = this.dialog.open(SpendPopupComponent, {
      disableClose: true,
      width: '800px',
      height: '250px',
      data: {officeId: this.authService.getOfficeId()},
    });

    this.dialogRef.afterClosed().subscribe(() => {
      this.dialogRef = undefined;
    });

    
  }

  private initForm() {
    this.addForm = new FormGroup({
      'phone': new FormControl(null, [this.phoneValidator, Validators.required]),
      'email': new FormControl(null), // Still required to submit agent name
      'agentId': new FormControl(null, Validators.required), // <-- Add this new field
      'state': new FormControl(null, Validators.required),
      'source': new FormControl(null, Validators.required),
      'office': new FormControl(this.authService.getOfficeId(), Validators.required),
      'healthId': new FormControl(null, Validators.required),
      'planType': new FormControl(null, Validators.required),
      'totalPremium': new FormControl(null, Validators.required),
    });
  }

  private phoneValidator(control: FormControl) {
    var regex = new RegExp(/\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/i);
    let valid = regex.test(control.value);
    return  valid ? null : {"phone": control.value};
  }

  private getStyles():void {
    this.startLoading();
    this.badRequest = null;
    var officeId = this.authService.getOfficeId();

    this.officeService.getStyles(officeId).subscribe(
      result => {
        //this.salesToday = result;

        this.setStyles(result);


        if (this.badRequest) {
          this.badRequest = null;
        }
      },
      error => {
        this.badRequest = error.error;
        this.stopLoading();
      },
      () => {
        this.stopLoading();
      }
    );
  }

  
  private setStyles(styles:IOfficeTheme[]):void {
    styles.forEach(item => {
      this.elementRef.nativeElement.style.setProperty(`--${item.name}`, item.value);
    });

    //this.elementRef.nativeElement.style.setProperty('--fontSize', '50vm');

    this.styleSharedService.updateStyle(styles.filter(item =>  item.name.includes('logo') ));
  }



  

  private getSalesCount() {
    this.startLoading();
    this.badRequest = null;
    var officeId = this.authService.getOfficeId();
    this.salesService.countToday(officeId).subscribe(
      result => {
        this.salesToday = result;

        if (this.badRequest) {
          this.badRequest = null;
        }
      },
      error => {
        this.badRequest = error.error;
        this.stopLoading();
      },
      () => {
        this.stopLoading();
      }
    );

    this.salesService.countAll(officeId).subscribe(
      result => {
        this.salesAll = result;

        if (this.badRequest) {
          this.badRequest = null;
        }
      },
      error => {
        this.badRequest = error.error;
        this.stopLoading();
      },
      () => {
        this.stopLoading();
      }
    );
  }

  private getSources() {
    this.startLoading();
    this.badRequest = null;

    this.sourceService.getByOffice(this.authService.getOfficeId()).subscribe(
      result => {
        this.sources = [...result];

        if (this.badRequest) {
          this.badRequest = null;
        }
      },
      error => {
        this.badRequest = error.error;
        this.stopLoading();
      },
      () => {
        this.stopLoading();
      }
    );
  }



  private getStates() {
    this.startLoading();
    this.badRequest = null;

    this.stateService.getByOffice(this.authService.getOfficeId()).subscribe(
      result => {
        this.states = [...result];

        if (this.badRequest) {
          this.badRequest = null;
        }
      },
      error => {
        this.badRequest = error.error;
        this.stopLoading();
      },
      () => {
        this.stopLoading();
      }
    );
  }

  get phone() { return this.addForm.get('phone'); }
  get email() { return this.addForm.get('email'); }
  get state() { return this.addForm.get('state'); }
  get source() { return this.addForm.get('source'); }
  get office() { return this.addForm.get('office'); }
  get healthId() { return this.addForm.get("healthId");} 
  get totalPremium() {return this.addForm.get("totalPremium");}
  get planType() { return this.addForm.get('planType'); }
  

  private cleanForm() {
    this.addForm.reset();
    this.office.setValue(this.authService.getOfficeId());
    //this.router.navigate(['']);
  }

  private startLoading() {
    this.isLoading = true;
    this.addForm.disable();
  }

  private stopLoading() {
    this.isLoading = false;
    this.addForm.enable();
  }

  add(): void {
    if (this.addForm.invalid) {
      return;
    }
    
    const agentId = this.addForm.get('agentId').value;
    const selectedAgent = this.agents.find(a => a.id === agentId);
      
    if (selectedAgent) {
      this.addForm.patchValue({ email: selectedAgent.name });
    }


    this.badRequest = null;
    this.startLoading();
    this.model.populateFromFormGroup(this.addForm);

    this.salesService.add(this.model).subscribe(
      result => {
        if (this.badRequest) {
          this.badRequest = null;
        }
        this.getSalesCount();
      },
      error => {
        this.stopLoading();
        if(error.status == 406)
        {
          this.showPopup();
          return;
        }

        this.badRequest = error.error;
        
      },
      () => {
        this.stopLoading();
        this.cleanForm();
      }
    );
  }

}
