import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OfficeRoutingModule } from './office-routing.module';
import { OfficeComponent } from './office/office.component';
import { MaterialModule } from '../material-module';
import { ReactiveFormsModule } from '@angular/forms';
import { PhoneMaskDirective } from '../directives/phone-mask.directive';
import { SpendPopupComponent } from './spend-popup/spend-popup.component';
import { OfficeSalesComponent } from './office-sales/office-sales.component';


@NgModule({
  declarations: [OfficeComponent, PhoneMaskDirective, SpendPopupComponent, OfficeSalesComponent],
  imports: [
    CommonModule,
    OfficeRoutingModule,
    MaterialModule,
    ReactiveFormsModule
  ]
})
export class OfficeModule { }
