import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IBadRequest } from 'src/app/interfaces/bad-request';
import { AddSpendModel } from 'src/app/models/spends/add-spend-model';
import { SpendsService } from 'src/app/services/spends.serice';

export interface DialogData {
  officeId: string;
}

@Component({
  selector: 'ast-spend-popup',
  templateUrl: './spend-popup.component.html',
  styleUrls: ['./spend-popup.component.scss']
})
export class SpendPopupComponent implements OnInit {

  public spendForm: FormGroup;
  private model: AddSpendModel = new AddSpendModel();
  public badRequest: IBadRequest = {
    errors: []
  };
  
  constructor(
    public dialogRef: MatDialogRef<SpendPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private spendService: SpendsService
    ) { }

  ngOnInit(): void {
    this.spendForm = new FormGroup({
      'totalSpend': new FormControl(null, [Validators.required, Validators.pattern("^\[0-9]+(\.[0-9][0-9])?$")]),
      'totalUnited': new FormControl(null, [Validators.required, Validators.pattern("^[0-9]*$")]),
      'totalACA': new FormControl(null, [Validators.required, Validators.pattern("^[0-9]*$")]),
    });

    
  }

  submit():void {
    
  
    if(!this.spendForm.valid)
      return;

      this.model.populateFromFormGroup(this.spendForm, this.data.officeId);

      this.spendService.add(this.model).subscribe(
        result => {
          if (this.badRequest) {
            this.badRequest = null;
          }
         
          this.dialogRef.close();

        },
        error => {
         
          
        },
        () => {
          
        }
      );
    
    //
  }

}
