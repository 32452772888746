import { FormGroup } from '@angular/forms';

export class AddSaleModel {
    phone: string;
    email: string;
    agentId: string;
    stateId: string;
    sourceId: string;
    officeId: string;
    healthId: string;
    planType: string;
    totalPremium: string;

    populateFromFormGroup(formGroup: FormGroup): void {
        this.phone = formGroup.get('phone').value;
        this.email = formGroup.get('email').value;
        this.agentId = formGroup.get('agentId').value;
        this.stateId = formGroup.get('state').value;
        this.sourceId = formGroup.get('source').value;
        this.officeId = formGroup.get('office').value;
        this.healthId = formGroup.get('healthId').value;
        this.planType = formGroup.get('planType').value;
        this.totalPremium = formGroup.get('totalPremium').value;
    }
}