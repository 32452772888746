<div class="container">
    <form [formGroup]="signinForm" (ngSubmit)="signin()">
        <mat-card>
            <div class="mat-card-header" *ngIf="isLoading">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
            <mat-card-content>
                <mat-form-field>
                    <input matInput type="text" placeholder="Email" formControlName="email">
                    <mat-error *ngIf="email.hasError('email') && !email.hasError('required')">
                        Email is not valid
                    </mat-error>
                    <mat-error *ngIf="email.hasError('required')">
                        Email can not be empty
                    </mat-error>
                </mat-form-field>
                <mat-form-field>
                    <input matInput [type]="isPasswordHidden ? 'password' : 'text'" placeholder="Password"
                        formControlName="password">
                    <button mat-icon-button matSuffix type='button' (click)="isPasswordHidden = !isPasswordHidden"
                        [attr.aria-label]="'Hide password'" [attr.aria-pressed]="isPasswordHidden">
                        <mat-icon>{{isPasswordHidden ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                    <mat-error *ngIf="password.hasError('required')">
                        Password can not be empty
                    </mat-error>
                </mat-form-field>
                <ng-container *ngIf="badRequest">
                    <mat-error *ngFor="let error of badRequest.errors">
                        {{ error }}
                    </mat-error>
                </ng-container>
            </mat-card-content>
            <mat-card-actions align="end">
                <button mat-flat-button color="accent" type="submit" [disabled]="signinForm.disabled">Sign In</button>
            </mat-card-actions>
        </mat-card>
    </form>
</div>