<h1 mat-dialog-title>Last Week's Spend</h1>

<div mat-dialog-content class="fields-container" [formGroup]="spendForm">
    <mat-form-field class="example-full-width">
        <mat-label>Total Spend</mat-label>
        <input matInput placeholder="Total Spend" formControlName="totalSpend">
    </mat-form-field>

    <mat-form-field class="example-full-width">
        <mat-label>Total United</mat-label>
        <input matInput placeholder="Total United" formControlName="totalUnited">
    </mat-form-field>

    <mat-form-field class="example-full-width">
        <mat-label>Total ACA</mat-label>
        <input matInput placeholder="Total ACA" formControlName="totalACA">
    </mat-form-field>

    
</div>

<ng-container *ngIf="badRequest">
    <mat-error *ngFor="let error of badRequest.errors">
        {{ error }}
    </mat-error>
</ng-container>

<div class="button-container">
    <button mat-flat-button class="report-button" color="accent" type="submit" (click)="submit()">Submit</button>
</div>