import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { RedirectGuard } from './auth/guards/redirect.guard';
import { AdminGuard } from './auth/guards/admin.guard';

const routes: Routes = [
  { path: '', pathMatch: 'full', children: [], canActivate: [RedirectGuard] },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    canLoad: [AdminGuard]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
