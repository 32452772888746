<mat-tab-group animationDuration="0ms" dynamicHeight >
    <mat-tab label="Ticker">
        
        <div class="mat-card-header" *ngIf="isLoading">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>

        <div class="veiw-container">
            <mat-form-field> 
                <mat-label>Choose a view</mat-label>
                <mat-select [formControl]="viewControl">
                  <mat-option value="daily" >Daily</mat-option>
                  <mat-option value="weekly">Weekly</mat-option>
                  <mat-option value="monthly">Monthly</mat-option>
                </mat-select>
              </mat-form-field>
        </div>
        <div class="container" >
            <mat-grid-list [cols]="columns" rowHeight="fit" gutterSize="4">
                <mat-grid-tile *ngFor="let office of tickerData">
                    <ast-ticker-item [item]="office"></ast-ticker-item>
                </mat-grid-tile>
            </mat-grid-list>
        </div>

        <div class="total">
            <span>{{total}}</span>
        </div>

    </mat-tab>


    <mat-tab label="Chart">
        <div class="mat-card-header" *ngIf="isLoading">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        
        <div class="chart-container" *ngIf="!isLoading">
            <div class="charts-info-container">
                <div class="charts-info">
                    <div>
                        <span>Sales Per Hour:</span><span class="charts-info-value" [class]="getPerHourClass()">{{data.salesPerHour}}</span>
                    </div>
                    
                    <div>
                        <span>Most Deal This Hour:</span><span class="charts-info-value">{{data.mostDealThisHour}}</span>
                    </div>

                    <div>
                        <span>Top Source This Hour:</span><span class="charts-info-value">{{data.topSourceThisHour}}</span>
                    </div>
                
                </div>

                <div class="top-week">
                    <span>Top 3 Sources This Week:</span>
                    
                    <div class="top-value" *ngFor="let item of data.topSourcesWeek; let i = index;"> 
                        {{i+1}}) {{item}}
                    </div>
                </div>

                <div class="top-month">
                    <span>Top 3 Sources This Month:</span>
                    
                    <div class="top-value" *ngFor="let item of data.topSourcesMonth; let i = index;"> 
                        {{i+1}}) {{item}}
                    </div>
                </div>
                
            </div>
            <div class="charts">
                <div class="dark">
                    <ngx-charts-bar-vertical
                        [view]="view"
                        [customColors] = "sourcesColors"
                        [results]="data.sources"
                        [gradient]="gradient"
                        [xAxis]="showXAxis"
                        [yAxis]="showYAxis"
                        [legend]="showLegend"
                        [showXAxisLabel]="showXAxisLabel"
                        [showYAxisLabel]="showYAxisLabel"
                        [xAxisLabel]="'Sources'"
                        [yAxisLabel]="'Sales'"
                        [yAxisTickFormatting]="chatFormat"
                        >
                    </ngx-charts-bar-vertical>
                       
                </div>
                <div class="dark">
                    <ngx-charts-bar-vertical
                        [view]="view"
                        [customColors] = "statesColors"
                        [results]="data.states"
                        [gradient]="gradient"
                        [xAxis]="showXAxis"
                        [yAxis]="showYAxis"
                        [legend]="showLegend"
                        [showXAxisLabel]="showXAxisLabel"
                        [showYAxisLabel]="showYAxisLabel"
                        [xAxisLabel]="'States'"
                        [yAxisLabel]="'Sales'"
                        [yAxisTickFormatting]="chatFormat">
                    </ngx-charts-bar-vertical>
                </div>
            </div>
        </div>
    </mat-tab>
  </mat-tab-group>








