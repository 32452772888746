import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';

import { environment } from 'src/environments/environment';

import { SignInModel } from './sign-in-model';
import { AuthService } from '../auth.service';
import { IBadRequest } from 'src/app/interfaces/bad-request';

@Component({
  selector: 'ast-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {

  private model: SignInModel = new SignInModel();

  public signinForm: FormGroup;
  public badRequest: IBadRequest;
  public isPasswordHidden: boolean = true;
  public isLoading: boolean = false;

  constructor(
    private titleService: Title,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.titleService.setTitle(environment.projectName + ": Sign In");
    this.signinForm = new FormGroup({
      'email': new FormControl(this.model.email, [
        Validators.required,
        Validators.email
      ]),
      'password': new FormControl(this.model.password, Validators.required)
    });
  }

  get email() { return this.signinForm.get('email'); }
  get password() { return this.signinForm.get('password'); }

  signin(): void {
    if (this.signinForm.invalid) {
      return;
    }
    this.badRequest = null;
    this.StartLoading();
    this.model.populateFromFormGroup(this.signinForm);
    this.authService.signin(this.model).subscribe(
      account => {
        if (this.badRequest) {
          this.badRequest = null;
        }
        this.authService.navigateToHome();
      },
      error => {
        this.badRequest = error.error;
        this.StopLoading();
      },
      () => {
        this.StopLoading();
      }
    );
  }

  private StartLoading() {
    this.isLoading = true;
    this.signinForm.disable();
  }

  private StopLoading() {
    this.isLoading = false;
    this.signinForm.enable();
  }

}
