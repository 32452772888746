import { Injectable, EventEmitter } from '@angular/core';

import { HubConnection, HubConnectionBuilder } from "@aspnet/signalr";

import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';

import { IOfficeTicker } from '../interfaces/offices/office-ticker';
import { IOfficeTickerData } from '../interfaces/offices/office-ticker-data';
import { IIdResult } from '../interfaces/id-result';
import * as signalR from '@aspnet/signalr';


@Injectable({
  providedIn: 'root'
})
export class TickerService {

  private tickerPath = environment.hubUrl + 'ticker';

  private hubConnection: HubConnection;
  intervalId: any;

  public data: IOfficeTickerData;
  public dataRecievedEvent: EventEmitter<any> = new EventEmitter();
  public updateRecievedEvent: EventEmitter<string> = new EventEmitter();
  private TIME_INTERVAL: number = 60;

  constructor(
    private authService: AuthService
  ) { }

  // public get total(): number {
  //   if (this.data) {
  //     return this.data.tickerData.reduce((sum, current) => sum + current.sales, 0);
  //   }
  //   return 0;
  // }

  public startConnection(): void {
    this.hubConnection = new HubConnectionBuilder()
      .withUrl(this.tickerPath, { accessTokenFactory: () => this.authService.getToken() })
      .build();

    this.start();

    this.hubConnection.onclose(() => {

      this.intervalId = setInterval(() => {

        if (this.hubConnection.state == 0) {
          this.start();
        } else {
          clearInterval(this.intervalId);
        }
      }, 1 * this.TIME_INTERVAL * 1000)
    });
  }

  private start() {
    try {
      this.hubConnection.start();
    } catch (error) {
      console.log('Failure to connect to Hub');
    }
  }

  public stopConnection(): void {
    this.hubConnection.stop();
  }

  public addTickerListener(): void {
    
    this.hubConnection.on('data', (data: IOfficeTickerData) => {
      this.data = data;
      this.dataRecievedEvent.emit();
      
    });

    // this.hubConnection.on('update', (update: IIdResult) => {
    //    this.data.find(x => x.id == update.id).sales++;
    //    this.updateRecievedEvent.emit(update.id);
    // });

    this.hubConnection.on('update', (data: IOfficeTickerData) => {
       this.data = data;
       this.updateRecievedEvent.emit();
    });
  }
}
