import { FormGroup } from '@angular/forms';

export class SignInModel {
    email: string;
    password: string;

    populateFromFormGroup(formGroup: FormGroup): void {
        this.email = formGroup.get('email').value;
        this.password = formGroup.get('password').value;
    }
}
